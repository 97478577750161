import React from 'react';
import { BiLoaderAlt } from 'react-icons/bi';

export default function LoadingSpinner() {
  return (
    <div className="text-body/50 flex items-center justify-center my-8" disabled>
      <BiLoaderAlt className="animate-spin h-5 w-5 mr-2" />
    </div>
  );
}
