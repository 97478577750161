import React, { Component } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useNavigate, Navigate } from 'react-router-dom';
import NewPasswordForm from './../components/NewPasswordForm';
import placeholder from './../assets/login.png';

const userData = JSON.parse(localStorage.getItem('_userData'));
const ASSCESS_TOKEN = userData && userData.data.token.access_token;

function withParams(Component) {
  return (props) => <Component {...props} navigation={useNavigate()} />;
}

class ForgetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      componentData: [],
    };
  }

  render() {
    if (ASSCESS_TOKEN) {
      return <Navigate to="/" />;
    }

    return (
      <HelmetProvider>
        <div>
          <Helmet>
            <title> IPGBridge - New Password </title>
          </Helmet>
          <div className="h-screen w-full flex bg-white">
            <div className="w-full flex items-center justify-center">
              <NewPasswordForm />
            </div>
          </div>
        </div>
      </HelmetProvider>
    );
  }
}

export default withParams(ForgetPassword);
