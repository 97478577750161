import React from 'react';

import { BrowserRouter as Router, Routes, Route, Navigate, Outlet } from 'react-router-dom';

import axios from 'axios';
import { Suspense } from 'react';
import Loader from './components/Loader';

/* IMPORT COMPORNENTS */
import Login from './pages/Login';
import Logout from './pages/Logout';
import ForgetPassword from './pages/ForgetPassword';
import NewPassword from './pages/NewPassword';
import ResetPassword from './pages/ResetPassword';
import ThankYou from './pages/ThankYou';
import PaymentError from './pages/PaymentError';
import { useTranslation } from 'react-i18next';

import { Link } from './pages';
import  Dashboard from './pages/Dashboard';
import { BaseComp } from './pages/baseComp';

import ScrollToTop from './ScrollToTop';

let user = false;

/* END OF IMPORT COMPORNENTS */
const userData = JSON.parse(localStorage.getItem('_userData'));
const ASSCESS_TOKEN = userData && userData.data.token.access_token;
const REFRESH_TOKEN = userData && userData.data.token.refresh_token;
const USER_KEY = userData && userData.data.user_key;
const USER_TYPE = userData && userData.data.user_type;

if (ASSCESS_TOKEN) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${ASSCESS_TOKEN}`;
  axios.defaults.headers.common['User-Key'] = `${USER_KEY}`;
  axios.defaults.headers.common['User-Rt'] = `${REFRESH_TOKEN}`;
  user = true;
}

const ProtectedRoute = ({ user, redirectPath = '/login' }) => {
  if (!ASSCESS_TOKEN) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};

const ProtectedRouteByType = ({ userTypes, redirectPath = '/' }) => {
  if (!userTypes.includes(USER_TYPE.toUpperCase())) {
    return <Navigate to={redirectPath} replace />;
  }

  return <BaseComp />;
};

function App() {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/login" element={<Login />} extend />
          <Route path="/forget-password" element={<ForgetPassword />} extend />
          <Route path="/new-password" element={<NewPassword />} extend />
          <Route path="/reset-password" element={<ResetPassword />} extend />
          <Route path="/thank-you" element={<ThankYou />} extend />
          <Route path="/payment-error" element={<PaymentError />} extend />
          <Route element={<ProtectedRoute user={user} />}>
            <Route path="/" element={<BaseComp />}>
              <Route path="/" element={<Dashboard />} />
              <Route path="/transactions" element={<Link.LinkManagement />} />
              <Route path="/create" element={<Link.LinkCreate />} />
              <Route path="/complete" element={<Link.LinkComplete />} />
              <Route path="/transactions/:id" element={<Link.LinkComplete />} />
            </Route>
            <Route path="/logout" element={<Logout />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default function WrappedApp() {
  return (
    <Suspense
      fallback={
        <span className=" top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <Loader />
        </span>
      }
    >
      <App className="z-30" />
    </Suspense>
  );
}
