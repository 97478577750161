import axios from 'axios';
import { api } from '../Settings';

export const getCountries = async (p = null) => {
  try {
    const request = await axios(api.getCountries, { params: p }).catch((err) => {
      if (err.response && err.response.status === 401) {
        window.location.href = '/logout';
      }
    });
    const response = request.data.data;
    return response;
  } catch (error) {
    console.log(`getCountries error: ${error}`);
    return false;
  }
};
