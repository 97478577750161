import React, { Component, useState, useEffect } from 'react';
import { FaSchool, FaUser, FaRegHandshake, FaChild } from 'react-icons/fa';
import {
  MdOutlineDirectionsRun,
  MdDashboard,
  MdSchool,
  MdOutlineAccountCircle,
  MdOutlineClose,
  MdMenu,
  MdOutlineSettings,
  MdOutlinePayments,
} from 'react-icons/md';
import { BiImages, BiLineChart } from 'react-icons/bi';
import { BsNewspaper, BsCalendarEvent } from 'react-icons/bs';
import { GiWorld } from 'react-icons/gi';
import { AiOutlineLogout, AiOutlineHome } from 'react-icons/ai';
import { NavLink } from 'react-router-dom';
// import { withTranslation } from 'react-i18next';
import { useTranslation } from 'react-i18next';

import logo from './../assets/logo.png';
import { RiParentFill } from 'react-icons/ri';

const userData = JSON.parse(localStorage.getItem('_userData'));
const USER_TYPE = userData && userData.data.user_type;
const name =
  userData && userData.data.user && userData.data.user.name
    ? `${userData.data.user.name}`
    : '';
const userEmail =
  userData && userData.data.user && userData.data.user.email
    ? `${userData.data.user.email}`
    : '';
const clientLogo =
  userData && userData.data.client && userData.data.client.logo_path
    ? `${userData.data.client.logo_path}`
    : logo;

const navigation = [
  {
    label: 'Home',
    url: '/',
    icon: <MdDashboard />,
  },
  {
    label: 'transactions',
    url: '/transactions',
    icon: <MdOutlinePayments />,
  },
/* 
  {
    url: '/create',
    label: 'payment_gateway',
    icon: <FaSchool />,
  }, */
  // {
  //   url: '/complete',
  //   label: 'payment-complete',
  //   icon: <MdOutlineDirectionsRun />,
  // },
  // /*   {
  //       url: "/parent-management",
  //       label: "Parent Management",
  //       icon:  <RiParentFill />
  //   }, */
  // {
  //   url: '/student-management',
  //   label: 'student-management',
  //   icon: <FaChild />,
  // },
  // {
  //   url: '/test-management',
  //   label: 'test-management',
  //   icon: <BiLineChart />,
  //   userTypes: ['ADMIN'],
  // },
  // {
  //   url: '/su-admin',
  //   label: 'su-admin',
  //   icon: <FaUser />,
  //   userTypes: ['ADMIN'],
  // },
  // {
  //   url: '/my-profile',
  //   label: 'my-profile',
  //   icon: <MdOutlineAccountCircle />,
  // },
  // {
  //   url: '/settings',
  //   label: 'Settings',
  //   icon: <MdOutlineSettings />,
  // },
  {
    url: '/logout',
    label: 'logout',
    type: 'action',
    icon: <AiOutlineLogout />,
  },
];

const Drawer = () => {
  const [show, setShow] = useState(true);
  const { t, i18n } = useTranslation();

  // constructor (props) {
  //     super(props)
  //     this.state = {
  //         show: true
  //     }

  //     this.toggleDrawer = this.toggleDrawer.bind(this);

  //     if(window.innerWidth < 1280 ){
  //         this.state.show = false;
  //     }

  //    // console.log(this.state)
  // }

  /*  static getDerivedStateFromProps(props, state) {
        return {show: props.show };
    } */

  useEffect(() => {
    if (window.innerWidth < 1280) {
      setShow(false);
    }
  }, []);

  const toggleDrawer = () => {
    let toggleValue = !show;
    setShow(toggleValue);
  };

  // render() {
  // const { t } = this.props;
  return (
    <>
      <div
        id="drawer-navigation"
        className={`fixed 2xl:relative z-40 min-h-screen overflow-y-auto bg-bg w-80  transition-all left-0 top-0 border border-r border-black/10  ${
          show ? 'min-w-[320px]' : 'hidden md:block min-w-[84px] w-[84px]'
        }`}
        tabIndex="-1"
        aria-labelledby="drawer-navigation-label"
      >
        <div
          className={`fixed max-h-screen overflow-auto top-0 left-0 pt-4 ${
            show ? 'min-w-[320px]' : 'min-w-[84px] w-[84px]'
          }`}
        >
          {show && (
            <NavLink to="/" className="mx-6 w-[150px] md:w-[250px] inline-block">
              <img className="logo w-full" src={logo} />
            </NavLink>
          )}

          <button
            onClick={toggleDrawer}
            type="button"
            data-drawer-hide="drawer-navigation"
            aria-controls="drawer-navigation"
            className={`text-primary bg-transparent hover:bg-gray-200 rounded-lg text-sm p-1.5 inline-flex items-center  ${
              !show ? 'absolute top-2.5 left-5' : 'absolute top-2.5 right-2.5'
            }`}
          >
            {show ? <MdOutlineClose className="text-2xl" /> : <MdMenu className="text-3xl" />}
          </button>

          <div className={`flex items-center px-4 ${!show ? 'mt-16 mb-6' : ' mt-4 mb-4 '}`}>
          {/*   <div
              className="nav-logo min-w-[48px] w-[48px] h-[48px] rounded-full bg-no-repeat bg-contain border border-primary bg-center"
              style={{ backgroundImage: `url("${clientLogo}")` }}
            ></div> */}
            {show && (
              <div className=" ml-2 ">
                <h5 className="text-base font-semibold text-body whitespace-nowrap max-w-[200px] text-ellipsis overflow-hidden">
                  {name}
                </h5>
                <a className="text-sm text-body">{userEmail}</a>
              </div>
            )}
          </div>

          <div className="p-4 overflow-y-auto max-h-[calc(100vh-210px)]">
            <ul className="space-y-2">
              {navigation.map((item, index) => {
                return (
                  (!item?.userTypes || item?.userTypes?.includes(USER_TYPE.toUpperCase())) && (
                    <NavLink
                      to={item.url}
                      key={index}
                      className={({ isActive }) =>
                        isActive
                          ? 'text-primary  flex items-center p-2 text-base font-normal rounded-lg group bg-primary/20'
                          : 'flex items-center p-2 text-base font-normal text-ash rounded-lg hover:bg-gray-100 group'
                      }
                    >
                      <div className={`flex justify-center  ${!show ? 'w-full' : ''}`}>
                        <div className={`menu-icon text-2xl`}>
                          <div className="w-6 h-6 text-primary transition duration-75">{item.icon}</div>
                        </div>
                      </div>
                      {show && <div className="ml-3">{t('navigation.' + [item.label.toLowerCase()])}</div>}
                    </NavLink>
                  )
                );
              })}
            </ul>
          </div>
        </div>
      </div>

      <div className="flex md:hidden items-center relative justify-center py-4">
        <button
          onClick={toggleDrawer}
          type="button"
          data-drawer-hide="drawer-navigation"
          aria-controls="drawer-navigation"
          className={`mx-2 text-primary bg-transparent hover:bg-gray-200 rounded-lg text-sm p-1.5 inline-flex items-center absolute left-0 top-1/2 -translate-y-1/2`}
        >
          {show ? <MdOutlineClose className="text-2xl" /> : <MdMenu className="text-3xl" />}
        </button>

        <NavLink to="/" className="w-[105px] inline-block">
          <img className="logo w-full" src={logo} />
        </NavLink>
      </div>
    </>
  );
  // }
};

export default Drawer;
