import { ArrowLongDownIcon, ArrowLongUpIcon, FunnelIcon } from '@heroicons/react/24/outline';
import { ClockIcon } from '@heroicons/react/24/solid';
import React, { useEffect, useRef, useState, Fragment } from 'react';
import { useAsyncDebounce, useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import { BiDownload, BiSearch } from 'react-icons/bi';
import { MdArrowDropDown } from 'react-icons/md';
import { CSVLink } from 'react-csv';
import { Popover } from '@headlessui/react';
import Flags from 'country-flag-icons/react/3x2';
import CountryListing from './../components/CountryListing';
import { api } from '../Settings';
import axios from 'axios';
import FileSaver from 'file-saver';
import { useTranslation } from 'react-i18next';
// import { getBatchTests } from '../services/getBatchTests';
import { urlUpdate } from '../pages/helpers';
var qs = require('qs');

const userData = JSON.parse(localStorage.getItem('_userData'));
const BASE_URL = userData && userData.base_url;

function TablePagination({
  selectCallBack,
  filters,
  columns,
  data,
  fetchData,
  countries,
  selectedIds,
  loading,
  pageCount: controlledPageCount,
  totalRow,
  actions: Actions,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter, sortBy, filterObj },
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      manualGlobalFilter: true,
      manualSortBy: true,
      initialState: {
        pageIndex: 0,
        pageSize: 25,
      }, // Pass our hoisted table state
      pageCount: controlledPageCount,
      autoResetSortBy: false,
      autoResetExpanded: false,
      autoResetPage: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const { t, i18n } = useTranslation();
  const [url, setUrl] = useState('');

  const GlobalFilter = ({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) => {
    const count = preGlobalFilteredRows;
    const [value, setValue] = useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
      setGlobalFilter(value || undefined);
    }, 700);

    return (
      <div
        className={`mt-4 md:mt-0 shadow relative overflow-hidden hidden rounded-md border border-black/10  bg-white w-96 ${
          loading && 'opacity-50 pointer-events-none'
        }`}
      >
        {Actions !== undefined ? <Actions /> : null}
        <BiSearch className="absolute left-2.5 top-3 text-xl text-icon" />
        <input
          value={value || ''}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={t('table.search')}
          type="search"
          className={`pl-10 p-2.5 outline-none w-full ${Actions !== undefined ? '' : 'self-end'}`}
        />
      </div>
    );
  };

  const [trggierFilter, setFilterChange] = useState([]);
  const [filterData, setFilters] = useState();
  const [filtered, setFilterStatus] = useState(false);
  const [filteredDownload, setFilterDownloadStatus] = useState(false);
  const [filteredBatch, setFilterBatchStatus] = useState(null);

  const [allSelected, setAllSelected] = useState(false);
  const allCb = useRef();

  useEffect(() => {
    setFilters(filters);
  }, [countries]);

  useEffect(() => {
    let search = globalFilter === undefined ? '' : globalFilter;
    fetchData(pageSize, pageIndex, search, sortBy, trggierFilter);
  }, [fetchData, pageIndex, pageSize, globalFilter, sortBy, trggierFilter]);

  useEffect(() => {
    if (allCb.current) {
      if (data.length > 0 && selectedIds.length === data.length) {
        allCb.current.checked = true;
      } else {
        allCb.current.checked = false;
      }
    }
  }, [selectedIds]);

  const SeletFilterItem = (f, o, close) => {
    close();
    const filter = filterData.filter((item) => item.key === f.key);
    console.log(o.id, f.label, filterData, 'sssssss');
    filter[0].value = o.id;
    filter[0].selected = o.name;

    setFilters(filterData);
    fetchData(pageSize, pageIndex, '', sortBy, filterData);

    setFilterChange(filterData);
    setFilterStatus(true);
    if (f.label.trim() == 'Batch') {
      setFilterDownloadStatus(true);
      setFilterBatchStatus(filter[0].value);
    }
  };

  const chooseCountries = (data, close) => {
    close();

    const filter = filterData.filter((item) => item.key === 'country');
    filter[0].value = data.id;
    filter[0].selected = data.name;

    setFilters(filterData);
    fetchData(pageSize, pageIndex, '', sortBy, filterData);

    setFilterChange(filterData);
    setFilterStatus(true);
  };

  const clearFilter = () => {
    filterData.map((item) => {
      item.value = null;
      item.selected = null;
    });

    setFilters(filterData);
    fetchData(pageSize, pageIndex, '', sortBy, []);
    setFilterStatus(false);
    setFilterDownloadStatus(false);
    setFilterChange(filterData);
  };

  const selectItems = (event) => {
    console.log(event);
    selectCallBack(event.target.checked);
  };

  return (
    <>
      {urlUpdate(url)}
      <div className="info-wrp flex flex-wrap justify-between  mb-8">
        <div className="flex items-center flex-wrap">
          {filterData?.map((filter, index) => {
            return (
              <Fragment key={index}>
                <Popover className="relative z-10">
                  {({ open }) => (
                    <>
                      <Popover.Button className="flex items-center mr-4 relative outline-none">
                        <div className="menu-icon text-xl text-icon">{filter.icon}</div>
                        <div className="menu-text text-sm ml-1.5 ">
                          {filter.selected ? filter.selected : filter.label}
                        </div>
                        <MdArrowDropDown
                          className={`text-lg text-body/50 transition-all duration-300  ${open ? 'rotate-180' : ''}`}
                        />
                      </Popover.Button>

                      <Popover.Panel className="filter-popup bg-white absolute left-0 top-6 z-10 shadow-2xl rounded-lg p-3 space-y-1 max-h-[300px] overflow-auto max-w-[300px] ">
                        {({ close }) =>
                          filter.key === 'country' ? (
                            <div className="w-48 h-[240px]">
                              <CountryListing
                                chooseCountries={chooseCountries}
                                close={close}
                                menuIsOpen={true}
                                defaultMenuIsOpen={true}
                                openMenuOnFocus={true}
                                maxMenuHeight={190}
                                closeMenuOnSelect={false}
                                isMulti={false}
                                className="filter-drop"
                              />
                            </div>
                          ) : (
                            filter.options?.map((opt, index) => {
                              const Flag = Flags[opt.alpha_2?.toUpperCase()];

                              return (
                                <span
                                  onClick={() => SeletFilterItem(filter, opt, close)}
                                  key={index}
                                  data-key={filter.key}
                                  data-label={opt.name}
                                  data-id={opt.id}
                                  className="whitespace-nowrap text-sm font-medium cursor-pointer hover:text-primary text-ellipsis overflow-hidden flex items-center"
                                >
                                  {Flag && <Flag title={opt.name} className="min-w-[24px] w-5 h-auto mr-2" />}
                                  {opt.name}
                                </span>
                              );
                            })
                          )
                        }
                      </Popover.Panel>
                    </>
                  )}
                </Popover>
              </Fragment>
            );
          })}

          {filtered && (
            <span onClick={clearFilter} className="text-primary cursor-pointer ml-8">
              {t('table.filter-clear')}
            </span>
          )}
        </div>

        <GlobalFilter preGlobalFilteredRows={totalRow} globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
      </div>

      <div className="text-sm bg-white shadow-md rounded-lg mb-4 relative text-body">
        <table {...getTableProps()} className="table w-full">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={`${column.class ? column.class : ''} border-b border-black/10 py-3 px-2 ${
                      index === headerGroup.headers.length - 1 ? 'text-right pr-4' : 'text-left'
                    }  ${index === 0 ? 'pl-4' : ''}`}
                  >
                    <div className="flex items-center">
                      {column.render('Header')}
                      {!column.disableSortBy && (
                        <span>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <ArrowLongDownIcon className="h-3 w-3 inline ml-1" />
                            ) : (
                              <ArrowLongUpIcon className="h-3 w-3 inline ml-1" />
                            )
                          ) : (
                            <FunnelIcon className="h-3 w-3 inline ml-1" />
                          )}
                        </span>
                      )}
                      {column.selectCb && (
                        <>
                          <div className="id flex items-center">
                            <div className="mb-[0.125rem] block min-h-[1.5rem] pl-[1.5rem]">
                              <input
                                className="relative float-left mt-[0.15rem] mr-[6px] -ml-[1.5rem] h-[1.125rem] w-[1.125rem] appearance-none rounded-[0.25rem] border-[0.125rem] border-solid border-[rgba(0,0,0,0.25)] bg-white outline-none before:pointer-events-none before:absolute before:h-[0.875rem] before:w-[0.875rem] before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] checked:border-primary checked:bg-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:ml-[0.25rem] checked:after:-mt-px checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem] checked:after:rotate-45 checked:after:border-[0.125rem] checked:after:border-t-0 checked:after:border-l-0 checked:after:border-solid checked:after:border-white checked:after:bg-transparent checked:after:content-[''] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:transition-[border-color_0.2s] focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-[0.875rem] focus:after:w-[0.875rem] focus:after:rounded-[0.125rem] focus:after:bg-white focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:after:ml-[0.25rem] checked:focus:after:-mt-px checked:focus:after:h-[0.8125rem] checked:focus:after:w-[0.375rem] checked:focus:after:rotate-45 checked:focus:after:rounded-none checked:focus:after:border-[0.125rem] checked:focus:after:border-t-0 checked:focus:after:border-l-0 checked:focus:after:border-solid checked:focus:after:border-white checked:focus:after:bg-transparent"
                                type="checkbox"
                                ref={allCb}
                                onChange={(e) => selectItems(e)}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.length > 0 ? (
              page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className={` border-b border-black/10 ${i % 2 === 1 && 'bg-black/5'}`}>
                    {row.cells.map((cell, index) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={`max-w-xs text-ellipsis overflow-hidden ${
                            cell.column.class ? cell.column.class : ''
                          }  py-2 px-2 text-black/50 ${index === row.cells.length - 1 ? 'text-right pr-4' : ''} ${
                            index === 0 ? 'pl-4' : ''
                          }`}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            ) : (
              <tr className="hover">
                <td colSpan={10000} className="text-center py-4 border-b border-black/10">
                  {t('table.no-data')}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {loading ? (
          <div className="absolute top-0 bottom-0 left-0 right-0 bg-black bg-opacity-5 rounded-md z-20 flex items-start justify-center">
            <div className="absolute p-3 bg-white w-36 shadow-md rounded-md text-center top-16">
              <div className="flex animate-pulse">
                <ClockIcon className="w-6 h-6 mr-1" /> <span>{t('table.export')}</span>
              </div>
            </div>
          </div>
        ) : null}
        <div className="flex items-center py-2 px-4 text-xs">
          <div className="item-count hidden md:block">
            {loading ? (
              // Use our custom loading state to show a loading indicator
              <>{t('general.loading')}</>
            ) : (
              <>
                {totalRow > 0 && (
                  <>
                    {t('table.showing')} {pageIndex * pageSize + 1} to{' '}
                    {(pageIndex + 1) * pageSize < totalRow ? (pageIndex + 1) * pageSize : totalRow} {t('table.of')}{' '}
                    {totalRow} {t('table.results')}
                  </>
                )}
              </>
            )}
          </div>
          <div className="pagination ml-auto">
            <button
              className={`p-1 w-6 rounded bg-primary text-white ${!canPreviousPage && 'opacity-50'}`}
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {'<<'}
            </button>{' '}
            <button
              className={`p-1 w-6 rounded bg-primary text-white ${!canPreviousPage && 'opacity-50'}`}
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              {'<'}
            </button>{' '}
            <button
              className={`p-1 w-6  rounded bg-primary text-white ${!canNextPage && 'opacity-50'}`}
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              {'>'}
            </button>{' '}
            <button
              className={`p-1 w-6  rounded bg-primary text-white ${!canNextPage && 'opacity-50'}`}
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {'>>'}
            </button>{' '}
          </div>
          <span className="ml-2 hidden md:block">
            {t('table.page')}{' '}
            <strong>
              {pageIndex + 1} {t('table.of')} {pageOptions.length}
            </strong>{' '}
            {t('table.total')} <strong>{preGlobalFilteredRows.length} </strong>
          </span>
          <span className="hidden md:block">
            &nbsp; | {t('table.go-to-page')}:{' '}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              className="border border-black/10 rounded w-12 py-1 pl-2 outline-none"
            />
          </span>{' '}
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            className="border border-black/10 rounded bg-white  py-1 px-2 ml-2"
          >
            {[25, 50, 100].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {t('table.show')} {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
}

export default TablePagination;
