import axios from 'axios';
import { api } from '../Settings';

export const getProfile = async (id) => {
  try {
    const request = await axios(api.getProfile, {
      params: { user_type: id },
    }).catch((err) => {
      if (err.response && err.response.status === 401) {
        window.location.href = '/logout';
      }
    });

    const response = request.data;
    return response;
  } catch (error) {
    console.log(`getProfile error: ${error}`);
    return false;
  }
};
