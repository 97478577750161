import React, { Component } from 'react';
import { titleUpdate } from './helpers';
import { MdError } from 'react-icons/md';

class PaymentError extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: true,
      isLoading: false,
      isError: false,
      componentData: [],
    };

    const urlParams = new URLSearchParams(window.location.search);
    const ref = urlParams.get('txn-reference');
    this.state.ref = ref;
    
  }

  render() {
    return (
      <>
        {titleUpdate('navigation.ipg')}

        <div className="h-screen bg-white flex items-center max-w-[800px] mx-auto">
          <div className="p-6  md:mx-auto">
            <MdError className="text-red-600 w-20 h-20 mx-auto my-6" />

            <div className="text-center ">
              <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">Payment Failed!</h3>

              <p className="text-body/50 my-2 text-lg">We're sorry, but your payment was unsuccessful.</p>

              {
                this.state.ref &&
                <p className="text-body/80 text-lg my-4" >Transaction ID: #{this.state.ref}</p>
              }

              <p className="text-body/50 my-2">
                If you continue to experience issues with your payment or have any questions, please don't hesitate to
                contact our customer support team. We're here to help resolve any problems you may be facing.
              </p>
              <p className="text-body/50 my-4">
                We appreciate your understanding and thank you for choosing our services.
              </p>
              {/* <div className="py-10 text-center">
                        <a href="#" className="px-12 bg-primary hover:bg-secondary text-white font-semibold py-3">
                            GO BACK 
                    </a>
                    </div> */}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PaymentError;
