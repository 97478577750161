import React, { Component } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useNavigate, Navigate } from 'react-router-dom';
import LoginForm from './../components/LoginForm';
import Footer from './../components/Footer';
import Lang from '../components/Lang';
import {getSettings} from '../services/getSettings';

const userData = JSON.parse(localStorage.getItem('_userData'));
const ASSCESS_TOKEN = userData && userData.data.token.access_token;

function withParams(Component) {
  return (props) => <Component {...props} navigation={useNavigate()} />;
}

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      componentData: [],
      clientName: ''
    };

    getSettings().then((data) => {
      console.log(data)
      this.setState({clientName: data.ipgbridge.client_name})
    })
    
  }

  render() {
    if (ASSCESS_TOKEN) {
      return <Navigate to="/" />;
    }

    return (
      <HelmetProvider>
        <div>
          <Helmet>
            <title> IPGBridge - Login </title>
          </Helmet>
          <div className="fixed right-0 top-0 w-56 h-10">
            <Lang />
          </div>
          <div className="h-screen w-full flex bg-white">
            <div className="w-full w-full flex flex-col items-center justify-center">
              <LoginForm />
            </div>
          </div>
        </div>
        <Footer className="mt-10"/>
      </HelmetProvider>
    );
  }
}

export default withParams(Login);
