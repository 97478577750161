import axios from 'axios';
import { api } from '../Settings';

export const getSettings = async (id) => {
  try {
    const request = await axios(api.getSettings ).catch((err) => {
      if (err.response && err.response.status === 401) {
        window.location.href = '/logout';
      }
    });

    const response = request.data;

    console.log(response);
    return response;
  } catch (error) {
    console.log(`getSettings error: ${error}`);
    return false;
  }
};
