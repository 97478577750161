import React, { Component } from 'react';
import logo from './../assets/server_down.jpg';

class APIError extends Component {
  render() {
    return (
      <div className="text-center text-body/50">
        <img alt="" src={logo} className="w-full max-w-[400px] mx-auto" />
        <span className="mt-4 inline-block">Sorry, Unable to connect with the data api, please try again later.</span>
      </div>
    );
  }
}

export default APIError;
