import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { titleUpdate } from './helpers';
import { MdOutlineAddBox } from 'react-icons/md';

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: true,
      isLoading: false,
      isError: false,
      componentData: [],
    };
  }

  componentDidMount() {
    const that = this;
    // that.setState({ isLoading: true, isError: false })
    /*  axios.get(api.getHome).then(response => {
       this.setState({
         componentData: response.data.components
       })
       this.setState({ isLoading: false });
     })
     .catch(function (error) {
       that.setState({ isLoading: false, isError: true  })
     }) */
  }

  render() {
    const { t } = this.props;
    return (
      <>
        {titleUpdate('navigation.title')}

          <Link
            to={'/create'}
            className={`flex items-center justify-center btn bg-primary shadow-md py-3 md:py-5 px-3 md:px-5 rounded-md text-white hover:shadow-lg hover:opacity-80 md:max-w-[300px] text-center mt-4`}
          >
            <MdOutlineAddBox className="text-3xl mr-2" />
            <span className="block md:text-lg font-semibold uppercase">{t('links.add')}</span>
          </Link>
      </>
    );
  }
}

export default withTranslation()(Dashboard);
