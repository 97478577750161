import React, { Component } from 'react';
import { titleUpdate } from './helpers';

class ThankYou extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: true,
      isLoading: false,
      isError: false,
      componentData: [],
    };

    const urlParams = new URLSearchParams(window.location.search);
    const ref = urlParams.get('txn-reference');
    this.state.ref = ref;

    //console.log(ref);
  }

  render() {
    return (
      <>
        {titleUpdate('navigation.ipg')}

        <div className="h-screen bg-white flex items-center">
          <div className="p-6  md:mx-auto">
            <svg viewBox="0 0 24 24" className="text-green-600 w-16 h-16 mx-auto my-6">
              <path
                fill="currentColor"
                d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
              ></path>
            </svg>
            <div className="text-center">
              <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">Payment Done!</h3>
             
              {
                this.state.ref &&
                <p className="text-body/80 text-lg my-4" >Transaction ID: #{this.state.ref}</p>
              }
             
              <p className="text-body/50 my-2 text-lg">Thank you for completing your secure online payment. <br/> Have a great day! </p>
              {/* <div className="py-10 text-center">
                        <a href="#" className="px-12 bg-primary hover:bg-secondary text-white font-semibold py-3">
                            GO BACK 
                    </a>
                    </div> */}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ThankYou;
