import { UpdatePathState, StateChangeComp, StateChangeCompUrl } from '../../hooks/path';

const titleUpdate = (title) => {
  return <StateChangeComp title={title} />;
};

const urlUpdate = (url) => {
  return <StateChangeCompUrl url={url} />;
};

export { titleUpdate, urlUpdate };
