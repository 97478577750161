import React, { Component } from 'react';
import logo from './../assets/logo.png';
import axios from 'axios';
import { useNavigate, NavLink } from 'react-router-dom';
import { BiLoaderAlt } from 'react-icons/bi';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { api } from '../Settings';

function withParams(Component) {
  return (props) => <Component {...props} navigation={useNavigate()} />;
}

class ForgetPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      emailError: '',
      apiError: '',
      successMessage: '',
      isLoading: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validate = this.validate.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    const val = target.value ? target.value : '';
    const value = target.type === 'checkbox' ? target.checked : val;
    const name = target.name;
    this.setState({ [name]: value });
    this.setState({ apiError: '', emailError: '', successMessage: '' });
  }

  validate() {
    let emailError = '';
    let apiError = '';

    this.setState({ apiError: '' });

    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!this.state.email || reg.test(this.state.email) === false) {
      emailError = 'Email Field is Invalid ';
    }

    if (emailError) {
      this.setState({ emailError });
      return false;
    }
    return true;
  }

  handleSubmit(event) {
    if (this.validate()) {
      const { navigation } = this.props;
      const that = this;

      that.setState({ isLoading: true });

      axios
        .post(api.resetPassword, {
          email: this.state.email,
        })
        .then(function (response) {
          let data = response.data;
          if (data.success) {
            that.setState({ successMessage: data.message });
          } else {
            that.setState({ apiError: data.message });
          }

          that.setState({ isLoading: false });
        })
        .catch(function (error) {
          that.setState({ isLoading: false, apiError: 'Unable to connect with the api.' });
          console.log(error);

          // that.setState({  successMessage: 'Password reset email successfully sent. Please check your emails and follow instructions.' });
        });
    }

    event.preventDefault();
  }

  render() {
    return (
      <>
        <div className="login-form mx-auto w-10/12 md:w-[430px]">
          <div className="w-full text-left text-base text-gray">
            <form method="POST" className="bg-white" onSubmit={this.handleSubmit}>
              <div className="text-center ">
                <img className="logo mx-auto mb-2" width="223" height="auto" src={logo} />
              </div>

              {!this.state.successMessage && (
                <>
                  <div className="mb-8 text-center lg:text-center">
                    <h1 className="text-2xl md:text-3xl font-normal text-heading">Forgot Password</h1>
                    <p>If you forgot your password type your email here, we will send a link to reset your password</p>
                  </div>
                  <div className="mb-2 md:mb-4">
                    <label className="block text-gray-700  font-semibold mb-2" htmlFor="email">
                      Email Address
                    </label>
                    <input
                      onChange={this.handleChange}
                      className="shadow appearance-none border border-secondary rounded w-full py-5 px-5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      value={this.state.email || ''}
                      name="email"
                      type="email"
                      placeholder="yourname@company.com"
                    ></input>
                    <span className="text-red-500 text-xs italic">{this.state.emailError}</span>
                  </div>
                </>
              )}

              {this.state.successMessage && (
                <span className="text-primary text-base italic mt-2 mb-8 block text-center">
                  {this.state.successMessage}
                </span>
              )}

              <div className="text-center">
                {!this.state.successMessage && (
                  <>
                    {!this.state.isLoading && (
                      <button
                        type="submit"
                        className="inline-block w-full mt-2 btn bg-primary shadow-md py-5 px-5 rounded-md text-white hover:shadow-lg hover:opacity-80"
                      >
                        Send
                      </button>
                    )}
                    {this.state.isLoading && (
                      <button
                        type="button"
                        className="w-full mt-2 btn bg-primary py-5 px-5 rounded-md text-white shadow-lg opacity-80 flex items-center justify-center cursor-default"
                      >
                        <BiLoaderAlt className="animate-spin h-5 w-5 mr-2" /> Loading...
                      </button>
                    )}
                  </>
                )}

                <span className="text-red-500 text-sm italic mt-2 text-left block">{this.state.apiError}</span>
                <p className="mt-2 md:mt-4">
                  <NavLink to="/login" className="font-semibold">
                    Back to Login
                  </NavLink>
                </p>
                {/* <p className='mt-4'>Don’t have an account ? <NavLink to="/registration" 
                        className=' text-secondary hover:text-primary'>Register here</NavLink></p> */}
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default withParams(ForgetPasswordForm);
